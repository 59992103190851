import { ChangeDetectorRef, Component, Input, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Subscription, forkJoin } from 'rxjs';
import { ApiService } from '../../service/api.service';
import { CommonModule } from '@angular/common';
import { environment } from '../../environments/environment';
import { ImgLoadedDirective } from '@app/shared/lib/directive/img.directive';
import { LoadingService } from '../../common/loading/loading.service';
import { LoadingComponent } from "../../common/loading/loading.component";
import { ImgxDirective } from '@app/shared/lib/directive/imgx.directive';

@Component({
  selector: 'app-casino-list',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ImgLoadedDirective,
    LoadingComponent,
    ImgxDirective
],
  templateUrl: './casino-list.component.html',
  styles: ``
})
export class CasinoListComponent {
    private subs: Subscription[] = [];

    @Input() tag: string = ''
    
    assetsUrl = environment.assetsUrl

    page: number = 1;
    items_total: number = 0;
    limit: number = 50;

    items_store:any = []
    
    category:any = {
        live_casino: `라이브 카지노`,
        search: `검색`
    }

    items_providers:any
    search_providers:Set<any> = new Set()
    orderBy:string = ''
    search_provider_text = `모든 제공업체`

    directMode!:boolean

    get isLoading() { return this.loadingService.isShow.getValue() }
    get percentage() { 
        if(this.items_total == 0 || this.items_store.length == 0) return 0;
        return Math.ceil((this.items_store.length / this.items_total) * 100)
    }
    constructor(
        private activatedRoute: ActivatedRoute,
        private apiService: ApiService,
        private loadingService: LoadingService,
    ) {}

    ngOnInit(): void {
        this.subs.push(
            this.activatedRoute.paramMap.subscribe(params => {
                const provider_idx = Number(params.get('provider_idx'))
                if(provider_idx) this.search_providers.add(provider_idx)
            })
        )

        this.subs.push(
            this.activatedRoute.data.subscribe((v:any) => {
                if(v.tag) {
                    this.directMode = true;
                    this.tag = v.tag || '';
                    this.fnGetProviders()
                } else {
                    this.directMode = false;
                }
            })
        );

        this.page = this.activatedRoute.snapshot.queryParams['page'] || 1;
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes['tag']) {
            const prev = changes['tag'].previousValue;
            const current = changes['tag'].currentValue
            if(current) {
                this.tag = current
                this.directMode = false;
                this.fnGetProviders()
            }
        }
    }

    fnGetProviders(){
        this.loadingService.show()
        this.apiService.post(`/casino/providers`, { tags: this.tag, providers: [...this.search_providers].join(',')}, false).subscribe({
            next: (jsondata:any) => {
                if(jsondata.success) {
                    this.items_providers = jsondata.data.sort((a:any,b:any) => {
                        if(a.name > b.name) return 1;
                        else if(a.name < b.name) return -1;
                        else return 0
                    });
                    this.fnGetData()
                }
            }, error: (e) => {
                this.loadingService.hide();
            }
        })
    }

    fnGetData(): void {
        const params = {
            page: this.page || '',
            tags: this.tag || '',
            orderby: this.orderBy || '',
            providers: ''
        }

        params.providers = [...this.search_providers].join(',')

        const url = (this.tag == 'live_casino' || this.tag == `slot_lobby`) ? '/casino/lobby' : '/casino/games'
        this.loadingService.show()
        this.apiService.post(url, params, false).subscribe({
            next: (jsondata:any) => {
                this.loadingService.hide()
                if(jsondata.success) {
                    this.items_store = (this.page > 1) ? [ ...this.items_store, ...jsondata.data.items] : jsondata.data.items
                    this.items_total = jsondata.data.items_total;
                }
            }, error: (e) => {
                this.loadingService.hide()
            }
        })
    }

    onProviders(idx:string, $event:any) {
        if(idx) {
            const checked = $event.target.checked
            if(checked) this.search_providers.add(idx)
            else this.search_providers.delete(idx)
        } else {
            this.search_providers.clear()
            this.items_providers.map((v:any) => v.checked = false)
        }

        this.search_provider_text= (this.search_providers.size) ? this.search_providers.size.toString() : `모든 제공업체`

        this.page = 1;
        this.fnGetData()
    }

    onOrderBy(orderBy:string) {
        this.page = 1;
        this.orderBy = orderBy
        this.fnGetData()
    }

    

    GoPage(page:any) {
        if(this.page === page) return;
        this.page = page;
        this.fnGetData()
    }
}
